/*
** Flexslider
* http://www.woothemes.com/flexslider/
*
* Copyright 2012 WooThemes
* Free to use under the GPLv2 license.
* http://www.gnu.org/licenses/gpl-2.0.html
*
* Contributing author: Tyler Smith (@mbmufffin)
*/

/* Browser Resets */
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
  outline: none;
}

.slides,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* FlexSlider Necessary Styles */
.flexslider {
  margin: 0;
  padding: 0;
}

.flexslider .slides > li {
  -webkit-backface-visibility: hidden;
  float: left;
  margin-right: -100%;
  position: relative;
  opacity: 1;
  display: block;
  z-index: 5;
}

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {
  width: 100%;
  display: block;
  @media (max-width: 767px) {
    height: 400px;
  } 
}

@media (max-width: 767px) {
  .flexslider.team-slider .slides img {
    height: auto;
  }
}

.flex-pauseplay span {
  text-transform: capitalize;
}

/* Clearfix for the .slides element */
.slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

html[xmlns] .slides {
  display: block;
}

* html .slides {
  height: 1%;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
* include js that eliminates this class on page load */
.no-js .slides > li:first-child {
  display: block;
}

/* FlexSlider Default Theme */
.flexslider {
  margin: 0 0;
  position: relative;
  zoom: 1;
}

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.loading .flex-viewport {
  max-height: 300px;
}

.flexslider .slides {
  zoom: 1;
}

/* Direction Nav */
.flex-direction-nav {
  *height: 0;
}

.flex-direction-nav a {
  text-decoration: none;
  display: block;
  width: 40px;
  height: 40px;
  margin: -20px 0 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  font-size: 0;
  color: transparent;
  text-align: center;
}

.flex-direction-nav .flex-prev {
  left: 40px;
}

.flex-direction-nav .flex-next {
  right: 40px;
  text-align: right;
}

.flexslider:hover .flex-prev {
  opacity: 0.7;
}

.flexslider:hover .flex-next {
  opacity: 0.7;
}

.flexslider:hover .flex-next:hover, .flexslider:hover .flex-prev:hover {
  opacity: 1;
}

.flex-direction-nav .flex-disabled {
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: default;
}

.flex-direction-nav a:before {
  font-family: "FontAwesome";
  font-size: 20px;
  content: '\f104';
  color: #ccc;
  position: absolute;
  top: 5px;
  left: 12px;
}

.flex-direction-nav a.flex-next:before {
  content: '\f105';
  left: 14px;
}

/* Pause/Play */
.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000;
}

.flex-pauseplay a:before {
  font-family: 'FontAwesome';
  font-size: 20px;
  display: inline-block;
  content: "\f01d";
}

.flex-pauseplay a:hover {
  opacity: 1;
}

.flex-pauseplay a.flex-play:before {
  content: "\f01d";
}

//using in woo
.flex-control-thumbs {
  margin: 1px -1px;
  position: static;
  overflow: hidden;
}

.flex-control-thumbs li {
  width: 25.2%;
  float: left;
  margin: 3px 0 1px;
  padding: 0 0 0 4px;
  &:first-child {
    padding-left: 1px;
    width: 24.4%;
  }
}

.flex-control-thumbs img {
  width: 100%;
  display: block;
  opacity: .7;
  cursor: pointer;
}

.flex-control-thumbs img:hover {
  opacity: 1;
}

.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}

/* custom nav for flex slider*/
.flex-custom-nav {
  text-align: center;
  position: absolute;
  bottom: 38px;
  right: 0;
  left: 0;
  z-index: 3;
  line-height: 1;
  transition: all 0.3s ease-in-out;
  .flex-control-nav {
    width: auto;
    display: inline-block;
    position: static;
  }
  .flex-custom-link {
    font-size: 12px;
    position: relative;
    bottom: 7px;
    margin: 0 8px;
    color: #cdcdcd;
    .ds &,
    .cs & {
      color: $lightColor;
    }
    &:hover {
      color: $colorMain;
    }
  }
  .page_slider:hover & {
    opacity: 1;
  }
}

/* Control Nav */
.page_slider .flexslider .flex-control-nav {
  counter-reset: number;
  flex-direction: column-reverse;
  justify-content: flex-end;
  top: 45%;
  left: 6.4%;
  width: auto;
  font-size: 0;
  line-height: 0;
  z-index: 10;
  @media(max-width: 991px) {
    bottom: 60px;
    display: block;
    flex-direction: row;
    left: auto;
    top: auto;
    width: 100%;
    text-align: center;

  }
}

.page_slider .flexslider .flex-control-nav li a:after {
  color: #fff;
  content: counter(number, decimal-leading-zero);
  counter-increment: number;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2em;
}
.page_slider .flexslider .flex-control-nav li a:hover::after {
  opacity: 0.5;
}

.page_slider .flexslider .flex-control-nav li a.flex-active:hover::after {
  opacity: 1;
}

.page_slider .social-flex {
  display: flex;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-66%) translateX(-2%) rotate(90deg);
  justify-content: center;
  z-index: 10;
  font-size: 12px;
  text-transform: uppercase;
  @media (max-width: 767px) {
    display: none;
  }
  p.social-heading {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.4em;
    margin-right: 34px; 
    margin-bottom: 0;
  
  }
  p.social-icons {
    letter-spacing: 0;
    a.fa { 
      margin-left: 13px;
      &:hover {
        opacity: 1 !important;
      }
    }
  }
}


