/*
** WordPress Page Links and Pagination
*/
.page-item,
.nav-links,
.page-links {
  clear: both;
  & > span,
  & > a {
    border: 2px solid #ededed;
    border-radius: 5px !important;
    color: #808080;
    display: inline-block;
    margin-right: 4px;
    margin-left: 0;
    padding: 0.5em 1.2em;
    min-width: 3em;
    text-align: center;
    line-height: inherit;
    &:hover {
      border-color: $colorMain2;
      background-color: transparent; 
    }
  }
  &.active > a,
  & > span {
    border-color: $colorMain2;
    cursor: not-allowed;
  }
  .fa {
    font-size: 18px;
  }
}

//page and comments links smaller
.comments-pagination,
.page-links {
  .page-numbers,
  & > span,
  a {
    //min-width: 2em;
  }
}

.page-links a:first-child {
  margin-left: 1em;
}

//blog styles
.pagination {
  margin-top: 60px;
  justify-content: flex-start;
  @media (max-width: 767px) {
    justify-content: center;
  }
}

//blog post
.post-nav {
  margin: 60px 0 0;
  position: relative;
  .nav-links {
    display: flex;
    flex-wrap: wrap;
    & > div {
      width: 50%;
      height: auto;
      //display: flex;
      //align-items: center;
      //justify-content: center;
      position: relative;
      padding: 30px 40px;
      text-align: right;

      transition: 0.2s opacity ease;
      &:hover {
        opacity: 0.9;
      }
      @media(max-width: 575px) {
        width: 100%;
      }
      .fa {
        font-size: 20px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
      &:first-child {
        text-align: left;
        .fa {
          right: auto;
          left: 0;
        }
      }
    }
    .nav-subtitle {
      @include small-text();
      text-align: center;
      min-width: 8em;
      padding: 0.25em 0.8em 0.05em;
      display: inline-block;
    }
    .nav-title {
      display: block;
    }

    a {
      @include cover();

    }
  }
  //for .cover-image
  .post-nav-image {
    display: none;
  }
  .cover-image {
    .post-nav-text-wrap {
      text-align: center;
    }
  }
}

.page-item.active .page-link {
  background-color: $colorMain2;
  border-color: $colorMain2;
}