/* Owl carousel v 2.1.6 styles */

/* Owl Carousel - Core */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-color-main-color: transparent;
  // position relative and z-index fix webkit rendering fonts issue
  position: relative;
  z-index: 1;

  //android fix https://github.com/OwlCarousel2/OwlCarousel2/issues/1790
  //touch-action: none;
  //android fix - https://github.com/OwlCarousel2/OwlCarousel2/issues/1884
  touch-action: manipulation;

  .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
  }

  //for box shadow not crop
  &.owl-shadow-items .owl-stage {
    padding-top: 11px;
    padding-bottom: 11px;
  }

  .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }

  .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px);

    //for items with box-shadow
    margin-right: -10px;
    margin-left: -10px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .owl-item {
    position: sticky;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-color-main-color: transparent;
    -webkit-touch-callout: none;
    .box-shadow {
      height: 100%;
    }
  }
  .owl-item img {
    -webkit-transform-style: preserve-3d;
  }

  .owl-nav.disabled,
  .owl-dots.disabled {
    display: none;
  }

  .owl-nav .owl-prev,
  .owl-nav .owl-next,
  .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.3s ease-in-out;
  }

  &.owl-loaded {
    display: block;
  }

  &.owl-loading {
    opacity: 0;
    display: block;
  }

  &.owl-hidden {
    opacity: 0;
  }

  &.owl-refresh .owl-item {
    display: none;
  }

  &.owl-drag .owl-item {
    //android fix - https://github.com/OwlCarousel2/OwlCarousel2/issues/1884
    //touch-action: manipulation;
    //touch-action: none;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &.owl-grab {
    cursor: move;
    cursor: grab;
  }

  &.owl-rtl {
    direction: rtl;
  }

  &.owl-rtl .owl-item {
    float: right;
  }

  //dots
  .owl-dots {
    text-align: center;
    -webkit-tap-color-main-color: transparent;
    line-height: 1;
    .owl-dot {
      height: 40px;
      zoom: 1;
      &:hover:after {
        color: #ccc;
      }
      &:after {
        color: #ccc;
        content: counter(number, decimal-leading-zero);
        counter-increment: number;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.2em;
        @media( max-width: 767px) {
          font-size: 14px;
        }
      }
      &.active:after {
        text-decoration: line-through;
        color: #ccc;
      }
      @media( max-width: 991px) {
        display: inline-block;
        margin: 0 10px; 
      }
    }
  }

  //nav
  .owl-nav {
    transition: all 0.3s ease;
    & > div {
      text-transform: uppercase;
      display: inline-block;
      right: 10px;
      &.disabled {
        opacity: 0.2;
        cursor: default;
        &:hover {
          cursor: default;
        }
      }

      //icon chevron
      @media (min-width: 768px) {
        i {
          font-size: 54px;
        }
      }

    }
    & > div:first-child {
      left: 10px;
      right: auto;
    }

  }
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* Owl Carousel - Animate Plugin */
.owl-carousel {
  .animated {
    animation-duration: 1000ms;
    animation-fill-mode: both;
  }
  .owl-animated-in {
    z-index: 0;
  }
  .owl-animated-out {
    z-index: 1;
  }
  .fadeOut {
    animation-name: fadeOut;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* Owl Carousel - Auto Height Plugin */

.owl-height {
  transition: height 500ms ease-in-out;
}

/* Owl Carousel - Lazy Load Plugin */

.owl-carousel {
  .owl-item {
    .owl-lazy {
      opacity: 0;
      transition: opacity 400ms ease;
    }

    img.owl-lazy {
      transform-style: preserve-3d;
    }
  }
}

/* Owl Carousel - Video Plugin */

.owl-carousel {
  .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000;
  }

  .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    background: url("/src/assets/img/owl.video.play.png") no-repeat;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: transform 100ms ease;
  }

  .owl-video-play-icon:hover {
    transform: scale(1.3, 1.3);
  }

  .owl-video-playing .owl-video-tn,
  .owl-video-playing .owl-video-play-icon {
    display: none;
  }

  .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity 400ms ease;
  }

  .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
  }
}

/*
THEMES
Owl Default Theme
*/

.owl-theme {
  &:hover .owl-nav {
    opacity: 1;
  }
  .owl-nav {
    position: absolute;
    top: 50%;
    opacity: 1;
    right: 0;
    left: 0;
    text-align: center;
    -webkit-tap-color-main-color: transparent;
    & > div {
      position: absolute;
      right: 10px;
      text-align: center;
    }
    .disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  // Styling dots
  .owl-dots {
    right: -2px;
    top: 37%;
    counter-reset: number;
    position: absolute;
    .item-media & {
      position: absolute;
      margin: 0;
      right: 5%;
      bottom: 20px;
    }
    @media( max-width: 991px) {
      position: static;
      margin-top: 20px;
    }
  }
}

/* .owl-center theme */
.owl-center {
  .owl-nav {
    top: 0;
    bottom: 0;
    position: static;
    margin-top: 0;
    opacity: 1;
    & > div {
      right: -15px;
      width: 50px;
      top: 0;
      bottom: 0;
      margin: 0;
      height: auto;
      background-color: rgba(#4c4c4c, 0.6);
    }
    & > div:first-child {
      left: -15px;
      right: auto;
      &:after {
        left: 13px;
      }
    }
    & > div:after {
      position: absolute;
      top: 50%;
      margin-top: -10px;
      color: #fff;
      font-size: 20px;
      left: 17px;
    }
  }
}

/*theme with nav at the top right corner*/
.top-right-nav {
  .owl-nav {
    position: absolute;
    top: -76px;
    right: -0.5em;
    @media (max-width: 767px) {
      top: -57px;
    }
    & > div {
      background: transparent;
      min-width: 1em;
      &:first-child {
        left: auto;
        right: 3em;
      }
      i {
        font-size: 20px;
        color: #4c4c4c;
      }
    }
  }
}

/*theme with text nav*/
.owl-text-nav {
  .owl-nav {
    display: none;

    text-align: center;
    -webkit-tap-color-main-color: transparent;

    @media (min-width: 1560px) {
      display: block;
      position: absolute;
      top: 50%;
      right: -13vw;
      left: -13vw;
    }

    @media (min-width: 1800px) {
      right: -15vw;
      left: -15vw;
    }


    & > div {
      position: absolute;
      right: 10px;
      text-align: center;
      margin-top: -30px;
      //for text instead of arrows
      & > span {
        font-weight: 900;
        line-height: 1;
        display: block;
        @media (min-width: 1560px) {
          line-height: 1;
          font-size: 38px;
          max-width: 5em;

        }
      }
    }
  }
}

.small-gallery-carousel {
  a {
    display: block;
    overflow: hidden;
    img {
      transition: all 0.3s ease-in-out;
    }
  }
  a:hover img {
    opacity: 0.5;
    transform: scale(1.2, 1.2);
  }
  .owl-nav {
    & > div {
      opacity: 1;
        right: 100px;
        margin-top: -8px;
        &:first-child {
          left: 100px;
        }
      i {
        font-size: 28px;
      }
    }
  }
}

/* owl images fix */
.owl-carousel {

  .owl-item {
    transition: opacity 0.2s ease 0.1s;
    opacity: 0;
  }
  .owl-item.active {
    opacity: 1;
  }
}

.owl-center .owl-item {
  opacity: 1;
}