/*
** Header
*/

.page_header {
  //same as .page_header_side
  transition: $headerTransition;

  position: relative;
  top: 0;

  left: 0;
  right: 0;
  width: 100%;

  z-index: 1000;
  margin: 0;
  padding: 0;

  //display_table fix on affix
  height: auto;

  .boxed &,
  &.affix {
    position: fixed !important;
    z-index: 1001;
    box-shadow: 0 2px 15px 0 rgba(100, 100, 100, 0.05);
    opacity: 0.98;
    //remove bottom border for affixed header with s-borderbottom class
    &:after {
      background-color: transparent;
    }
  }
  //hidding top border on affixed header
  &.affix.s-bordertop::before {
    display: none;
  }

  @media (max-width: 991px) {
    &.mobile-active {
      z-index: 1003;
      opacity: 1;
    }
  }

  //fix bug when refresh page at the bottom and header goes down
  &.affix-bottom {
    //!important is necessary
    top: 0 !important;
    position: fixed;
  }

  //min height for mobile toggler be always on header
  //for prevent header mobile menu toggler overlap bordered header
  // 62 - broken design on 60px color toggler - back to 60
  .row {
    min-height: 60px;
  }


  //fix header buttons dropdown
  .dropdown {
    //white-space: nowrap;
  }

  a {
    display: inline-block;
  }

  .btn {
    margin-bottom: 0;
  }
}

//when refreshing page - header height is smaller because it is affixed
//fixing
//absolute header when using header on slider or on title (for parallax or image background)
.header_absolute {
  position: relative;
  .page_header,
  .page_header_wrapper {
    z-index: 1002;
    position: absolute;
    right: 0;
    left: 0;
    &.main-header-side {
      z-index: 1051;
      left: 100%;
    }
  }

  //&.cover-background {
    .page_topline,
    .page_toplogo,
    .page_header_wrapper,
    .page_title {
      background-color: transparent;
    }

    //fix for boxed layout
    #canvas:not(.boxed) & .page_header.affix-top {
      background-color: transparent;
    }
  //}

}

//other header sections
.page_topline {
  line-height: 1.4;

  &.small-text i {
    font-size: 14px;
  }

  .icon-inline {
    justify-content: center;
  }
  .social-icons {
    display: inline-block;
  }
  //topline menu
  .widget_nav_menu {
    display: inline-block;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
    }
  }

  .dropdown-menu {
    margin: 23px 0 0 -25px;
    min-width: auto;
  }
  .dropdown-language {
    //flags
    a > img {
      max-width: 16px;
      position: relative;
      bottom: 1px;
      margin-right: 6px;
    }
  }

  //news owl-carousel
  .owl-news-slider-dates {
    font-weight: 700;
  }
  .topline-news-item {
    height: 2em;
    line-height: 2em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 90px;
    .cat-links {
      display: inline;
    }
  }
  .topline-news-nav-arrow {
    border: 1px solid $darkgreyColor;
    border-radius: 50%;
    font-size: 10px;
    position: relative;
    bottom: 20px;
    line-height: 38px;
    width: 40px;
    transition: opacity 0.2s ease-in-out;
    &:hover {
      opacity: 0.5;
    }
  }
  .owl-nav > div {
    opacity: 1;
  }

}

.page_toplogo {
  @media (min-width: 992px) {
    .media + .media {
      margin-left: 60px;
    }
  }

  .widget_search form, .widget_product_search form,
  .widget_search input, .widget_product_search input {
    max-width: none;
  }

  @media (max-width: 991px) {
    .media {
      margin: 10px;
    }
  }
}

.page_topline,
.page_toplogo,
.page_header {
  .container-fluid {
    @media(min-width: 1500px) {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
}

.page_topline.with-padding,
.page_header.with-padding {
  .container-fluid {
    @media(min-width: 1500px) {
      padding-left: 120px;
      padding-right: 120px;
    }
  }
}

.page_header.ls.affix-top {
  .logo {
    color:  $lightColor;
  }
}

.page_header.ls.affix {
  .logo {
    color:  $colorMain7;
    .logo-text {
      border-color: $colorMain7;
    }
  }
}
  
.header-layout2 {
  a.logo {
    .logo-text {
      color: $colorMain7;
      border-color: $colorMain7;
    }
  }
  a.search_modal_button {
    color: $colorMain7;
  }
  .icons-include {
    .icon-styled {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    }
    .search_modal_button {
      padding: 0;
    }
    a i:hover {
      color: $colorMain7 !important;
    }
  }
}

.page_topline.with-padding .icon-inline {
  justify-content: flex-end; 
}

@import 'header/header_includes';
@import 'header/logo';
@import 'header/search_modal';