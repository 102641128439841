/*.widget_slider*/

.widget_slider {
  h4:first-child {
    font-weight: 400;
    text-transform: none;
    line-height: 1;
    margin-top: -1em;
    margin-bottom: 0.3em;
  }
  .cat-links {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: -4px;
  }
}
