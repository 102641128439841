/* Testimonials shortcode */
.testimonials-slider .quote-item {
  text-align: center;
  padding: 5px 0; 
}

@media (min-width: 992px) {
  .testimonials-slider .quote-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}



@media (min-width: 992px) {
  .testimonials-slider .quote-item .quote-inner {
    text-align: left;
    padding-right: 90px;
  }
}

.testimonials-slider .quote-item .quote-inner .quote-meta {
  letter-spacing: 0.4em;
  margin-left: -9px;
  margin-top: 48px;
  margin-bottom: 20px;
  color: #808080;  
  @media (max-width: 767px) {
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: 0;
  }
}

.testimonials-slider .quote-item .quote-inner .quote-content {
  font-size: 20px;
  font-style: italic;
  font-weight: 300;
  color: #808080;
  line-height: 30px;
}

@media (min-width: 992px) {
  .testimonials-slider .quote-item .quote-inner .quote-signature {
    margin-left: -20px;
    margin-top: 29px;
  }
}

.quote-image {
  position: relative;
  display: inline-block;
  margin-bottom: 25px;
}

@media (min-width: 992px) {
  .quote-image {
    margin-bottom: 0;
    margin-right: 60px;
  }
}

.quote-image:after {
  line-height: 60px;
  content: url('/src/assets/images/quote-icon.png');
  position: absolute;
  top: 50%;
  margin-top: -30px;
  right: -30px;
  width: 60px;
  height: 60px;
  border: 0;
  border-radius: 50%;
  background: linear-gradient(to right, $colorMain 0%, $colorMain2 100%);
  color: #ffffff;
}

@media (max-width: 576px) {
  .quote-image:after {
    display: none;
  }
}

.quote-image img {
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background: #ffffff;
  max-width: 370px;
  padding: 10px;
}

@media (max-width: 576px) {
  .quote-image img {
    max-width: 100%;
  }
}

.quote-image + p {
  margin: 30px 0 20px;
  letter-spacing: 0.4em;
}

.quote-signature {
  @media (max-width: 991px) {
    margin-top: 30px !important;
    margin-bottom: 10px !important;
  }
}