/*
** Special Heading
*/
h5.special-heading {
  font-weight: 200;
  text-transform: uppercase;
  font-size: 24px;
  letter-spacing: 0.1em;
   @media (max-width: 575px) {
      font-size: 20px;
    }
}

.special-heading {
  word-wrap: break-word;
  font-size: 38px;
  font-weight: 900;
  line-height: 1;
  &.text-lowercase {
    font-size: 62px;
    font-weight: 300;
    margin-bottom: 0;
    line-height: 1.1;
  }
  &.big,
  .text-uppercase {
    font-size: 40px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.2em;
    @media (max-width: 575px) {
      font-size: 30px;
    }
  }
  &.big {
    margin-top: 0.65em;
    margin-bottom: 0.65em;
  }
  @media (max-width: 1600px) {
    &.big,
    &.text-lowercase,
    &.text-uppercase {
      font-size: 42px;
      line-height: 1;
    }
  }

  & + h3 {
    margin-top: -5px;
  }

  & + p {
    @include small-text();
    @media(min-width: 1200px) {
      &.extra-letter-spacing {
        letter-spacing: 2em;
      }
    }
  }
  &.text-center + p {
    text-align: center;
  }

  h3.small & {
    line-height: 1;
    font-size: 38px;
  }

}

h3.small {
  font-size: 20px;
  margin-bottom: 36px;
}

h5.special-heading {
  margin: -3px 0 0;
  letter-spacing: 0.2em; 
}

.special-heading + h1 {
  margin-top: -11px;
  text-transform: uppercase;
  letter-spacing: 0.2em;  
  margin-bottom: 0;
   @media (max-width: 575px) {
    font-size: 35px;
  }
}

