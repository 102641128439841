@import './../colors.scss';
@import './../variables.scss';

.mapa {
    background-image: url('../assets/mapa.jpg');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 300px;
}
.rodape {
    display: block;
    background-color: -moz-linear-gradient( 0deg, $redDark 29%, $redLight 81%);
    background: -webkit-linear-gradient( 0deg, $redDark 29%, $redLight 81%);
    background-color: -ms-linear-gradient( 0deg, $redDark 29%, $redLight 81%);
    h6 {
        @include rfs(12);
        color: $white;
        text-align: center;
        font-weight: 500;
        text-transform: uppercase;
    }
}