/*
** Easy pieChart
*/

.chart {
  position: relative;
  display: inline-block;
  min-height: 150px;
  min-width: 150px;
  margin-bottom: 10px;
  text-align: center;

  .chart-meta {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);

    h4 {
      font-size: 20px;
      margin: 0;
      letter-spacing: 0.2em;
    }
  }
}

.percent {
  font-family: 'Poppins', sans-serif;
  display: inline-block;
  font-size: 62px;
  font-weight: 900;
  z-index: 2;
}

.percent:after {
  content: '%';
}

.angular {
  margin-top: 100px;
  .chart {
    margin-top: 0;
  }
}