@import './assets/scss/main.scss';
@import './variables.scss';


.element {
    @include rfs(20);
    background-color: blue;
    color: white;
    padding: 20px;
  }

  * {
    outline: none;
    &:focus,
    &:active,
    &:visited {
      outline: none;
    }
  }