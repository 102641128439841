//Colors template
$gray-light: #f5f5f5;
$gray-bg-color: #f3eef3;
$text-color-dark: #4c4c4c;
$text-color-light: #808080;
$white: #ffffff;
$redLight: #e32620;
$redDark: #901a11;
$whats: #41b47a;

.redDark {
    color: $redDark;
}
.redLight {
    color: $redLight;
}
.gray-dark {
    color: $text-color-dark;
}
.whats {
    color: $whats;
}