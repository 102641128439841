/*
** Blog
*/

.blog_slider {
  margin-bottom: 60px;
  @media (min-width: 992px) {
    .intro_layers_wrapper {
      left: 130px;
      right: 130px;
    }
  }
  @media(max-width: 767px) {
    display: none;
  }
}

.blog-featured-posts {
  .post {
    height: 100%;
  }
}

//entry title on singular will be SHOWN - title will be HIDDEN in title section
.entry-title,
.entry-title:last-child {
  text-transform: none;
  margin: 6px 0 0;
  line-height: 1;
  font-size: 30px;
  letter-spacing: 0;
  @media (max-width: 767px) {
    font-size: 24px;
  }
}

//for blog greed and featured posts
h4.entry-title {
  font-size: 24px;
  margin-bottom: 0.7em;
}

//for single post
.item-media+ .item-content {
  h1.entry-title {
    margin-top: 5px;
  }
}


.post {
  position: relative;

  //sticky
  & > .fa {
    position: absolute;
    right: 9px;
    top: 7px;
    color: $lightColor;
    transform: rotate(45deg);
    z-index: 2;
    &:before {
      position: relative;
      z-index: 3;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 2;
      top: -14px;
      left: -35px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 40px 40px 40px;
      border-color: transparent transparent $colorMain transparent;

    }
  }

  & + & {
    margin-top: 60px;
  }

  //blog feed
  h3.entry-title {
    & + .entry-meta {
      margin-top:-0.65em;
    }
  }

  //cover image post
  &.cover-image {
    padding: 35px 30px 30px;
    @media (min-width: 992px) {
      padding: 80px 93px 77px;
    }
    text-align: center;
    height: auto;
    .post-thumbnail {
      display: none;
    }
  }

  ul, ol {
    margin-bottom: 40px;
  }

}
.post-thumbnail {
  text-align: center;
  .owl-dots {
    position: absolute;
    bottom: 30px;
    right: 0;
    left: 0;
  }
  & + .entry-content {
    margin-top: 1.5em;
  }
}

//password protected
.post-password-form {
  input[type="password"] {
    margin-top: 0.5em;
    width: auto;
  }
  input[type="submit"] {
    position: relative;
    top: -2px;
  }
}

.entry-footer,
.entry-meta {
  line-height: 1;
  clear: both;
  a {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }
}

.entry-footer {
  margin-top: 46px;
  margin-bottom: -3px;
  @media (max-width: 767px) {
    margin: 15px 0 0;
  }
  .fa {
    margin-right: 6px;
  }
}

//cleaning floats
.entry-content,
.entry-footer,
.entry-header {
  &:before,
  &:after {
    display: block;
    content: '';
    clear: both;
  }
}

.post-title {
  margin-top: 8px;
}

.post-tags {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
}
.post-data {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  @media (max-width: 767px) {
    display: none;
  }
  h6 {
    color: #fff;
    margin-top: 15px;
    line-height: 28px;
    font-size: 40px;
    span {
      letter-spacing: 0;
      font-size: 20px;
      display: block;
    }
  }
}

.header-content {
  margin-top: 3px;
  margin-left: 20px;
  @media (max-width: 767px) {
    margin-left: 0;
    margin-top: 0;
  line-height: 1;
  }
}

.post .post-tags {
   a, i {
    margin-right: 5px;
   }
}

.post-color1 {
  .post-tags {
    a, i {
      color: $colorMain;
    }
    a:hover {
      color: $colorMain2;
    }
  }
}

.post-color2 {
  .post-tags {
    a, i {
      color: $colorMain7;
      margin-right: 5px;
     }
     a:hover {
      color: $colorMain3;
    }
  }
  .entry-title a:hover {
    color: $colorMain7;
  }
  .entry-meta a:hover {
    color: $colorMain7;
  }
}

.post-color3 {
  .post-tags {
    a, i {
      color: $colorMain6;
      margin-right: 5px;
     }
     a:hover {
      color: $colorMain5;
    }
  }
  .entry-title a:hover {
    color: $colorMain6;
  }
  .entry-meta a:hover {
    color: $colorMain6;
  }
}

.widget-theme-wrapper .cs {
  border-radius: 511px;
}

.item-content.bg-maincolor {
  opacity: 0.85;
}

.post .entry-header  {
  display: flex;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    margin-bottom: 15px;
    display: block;
  }
}

.post-footer {
  margin-top: 30px;
   @media (max-width: 767px) {
    margin-top: 20px;
  }
}

//edit link
.entry-footer,
.entry-header {
  .edit-link {
    float: right;
    a:before {
      font-family: 'FontAwesome';
      content: '\f040';
      padding-right: 0.4em;
    }
  }
  //for woo
  .entry-title + .edit-link {
    position: absolute;
    right: 15px;
    top: 0.5em;
  }
}

.post-adds {
  display: flex;
  background-color: $backgroundColor;
  line-height: 1;
  align-items: center;
  margin-bottom: 30px;
  i {
    width: 1em;
  }
  a {
    display: inline-block;
    margin: 0;
  }
  .dropdown-menu {
    padding: 0;
    border: none;
  }
  .share_buttons {
    display: flex;
  }
  .share_button {
    background-color: $colorMain;
    color: $lightColor;
    line-height: 60px;
    padding: 0 21px;
  }
  .like_button {
    color: $colorMain;
    line-height: 60px;
    padding: 0 21px;
    border-right: 1px solid $borderColorRGBA;
  }
  .votes_count {
    padding: 0 21px;
  }
  .views-count {
    margin-left: auto;
    padding: 22px 30px;
    background-color: $colorMain;
    color: $lightColor;
  }
  @media(max-width: 576px) {
    & {
      flex-wrap: wrap;
    }
    .share_button {
      line-height: 40px;
      padding: 0 11px;
    }
    .like_button {
      line-height: 40px;
      padding: 0 11px;
    }
    .views-count {
      padding: 10px 15px;
      width: 100%;
    }
  }
}

/*gallery post*/
.post {
  .gallery {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7px;

    .gallery-item {
      width: 50%;
      padding: 0 7px;

      figcaption {
        padding: 20px 0 5px;
      }
    }

    @media (min-width: 992px) {
      &.gallery-columns-1 .gallery-item {
        width: 100%;
      }
      &.gallery-columns-2 .gallery-item {
        width: 50%;
      }

      &.gallery-columns-3 .gallery-item {
        width: 33.33%;
      }

      &.gallery-columns-4 .gallery-item {
        width: 25%;
      }

      &.gallery-columns-5 .gallery-item {
        width: 20%;
      }

      &.gallery-columns-6 .gallery-item {
        width: 16.66%;
      }

      &.gallery-columns-7 .gallery-item {
        width: 14.28%;
      }

      &.gallery-columns-8 .gallery-item {
        width: 12.5%;
      }

      &.gallery-columns-9 .gallery-item {
        width: 11.11%;
      }
    }

  }
}

/*post author*/
.author-bio {
  margin-top: 48px;
  @media (max-width: 767px) {
    margin-top: 60px;
  }
  a.fa {
    margin-bottom: 0;
  }
  .item-media {
    border-radius: 50%;
    border: 10px solid #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    width: 270px;
    margin: auto;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  h5 {
    margin: 0; 
  }
  p {
    margin: 0; 
  }
  .bio-desk {
    margin: 13px 0; 
  }
  .item-content {
    margin-left: 30px;
    @media (max-width: 1199px) {
      margin-left: 0;
    }
  }
  @media (max-width: 992px) {
    .content-padding .item-content {
      padding: 30px 5px;
    }
  }
}

/*comments*/
.comments-title {
  margin-bottom: 28px;
  @media (max-width: 767px) {
    margin-bottom: 21px;
  }
}

.comments-area {
  margin-top: 75px;
  padding: 50px 60px 60px; 
  @media (max-width: 767px) {
    margin-top: 60px;
    padding: 30px;
  }
  .comment-list {
    list-style: none;
    padding: 0;
    border-top: 1px solid #ebeaeb;
    border-bottom: 1px solid #ebeaeb;
    ol.children {
      border-top: 1px solid #ebeaeb;
      border-bottom: 1px solid #ebeaeb;
      list-style: none;
      padding-left: 35px;
      @media (min-width: 1200px) {
        padding-left: 80px;
      }
      ol.children ol.children {
        padding-left: 35px;
      }
      .comment-meta img {
        max-width: 60px;
        left: -45px;
        @media (max-width: 575px) {
          left: -30px;
        }
      }
      .comment-body {
        margin-left: 47px;
        margin-bottom: 3px;
        @media (max-width: 575px) {
          margin-left: 10px;
        }
      }
    }
  }
}

.comment-body {
  position: relative;
  margin-left: 70px;
  margin-bottom: 3px;
  padding: 10px 0 20px 40px;
  margin-top: 40px;
  ol,
  ol ol {
    padding-left: 20px;
  }
}
.comment-meta {
    margin-bottom: -3px; 
  //avatar
  img {
    position: absolute;
    left: -70px;
    border: 5px solid #fff; 
    border-radius: 50%;
    top: -1px;
    max-width: 80px;
  }

  .comment-author{
    font-size: 16px;
    @media(max-width: 575px) {
      font-size: 12px;
    }
  }
  .comment-metadata a {
    color: $colorMain2;
    &:hover {
      color: $colorMain;
    }
    &:before {
      position: relative;
      top: -4px;
      color: #4c4c4c;
      font-size: 5px;
      padding: 0 7px;
      content: "\f111";
      font-family: 'FontAwesome';
      @media(max-width: 575px) { 
        display: none;
      }      
    }
  }

  b {
    font-weight: inherit;
  }
}

@media(max-width: 575px) {
  .comment-body {
    margin-left: 20px;
    margin-top: 37px;
    margin-bottom: 0;
  }
  .comment-meta img {
    max-width: 65px;
    left: -34px;
  }
}

.reply a i:hover {
  color: $colorMain2;
}

//links
.reply {
  a {
    font-size: 14px;
    @media(max-width: 575px) {
      font-size: 12px;
    }
  }
}

.comment-content {
  p {
    margin-bottom: 0.5em;
  }
}

.no-comments,
.comment-awaiting-moderation {
  opacity: 0.7;
}

.comments-pagination {
  margin: 2em 0 3em;
}

/*comment respond form*/
.comments-area > .comment-respond {
  margin-top: 46px;
    @media (max-width: 767px) {
      margin-top: 20px;
  }
}
.comment-form {
  margin-top: 19px;
  display: flex;
  flex-wrap: wrap;
  @media(max-width: 767px) { 
    display: block;
  }
  p {
    margin-bottom: 10px;
    width: 100%;
    &.form-submit {
      width: auto;
      margin-top: 20px;
      margin-bottom: 0;
      @media(max-width: 767px) { 
        margin-top: 0;
      }
    }
  }
  label {
    display: none;
  }
  textarea,
  input {
    width: 100%;
  }
  //remember me checkbox appears with our custom designed checkbox, so hiding it
  input[type=checkbox] {
    margin: 0;
    visibility: hidden;
  }
  @media(min-width: 768px) {
    .comment-form-author,
    .comment-form-email {
      width: 50%;
    }
    .comment-form-author {
      padding-right: 20px;
    }
  }
  .form-group {
    margin-bottom: 20px; 
  }
  .form-control {
    border-radius: 5px;
  }
  textarea {
    max-height: 120px;
  }
}

/* post formats */

.format-status {
  border-radius: 5px;
  .entry-title {
    margin-bottom: 26px;
  }
  .cat-links {
    margin-bottom: 5px;
  }
  &.post .entry-title + .entry-meta {
    margin:0 0 25px;
  }
  .entry-date {
    margin-top: -7px;
  }
  .entry-header {
    display: block;
    margin-bottom: 0; 
  }
  &.s-overlay.ds:before {
    border-radius: 5px;
  }
  .entry-content {
    color: #fff;
    margin-bottom: 30px;
  }
  .entry-meta a {
    letter-spacing: 0;
    color: #fff !important;
    &:hover {
      opacity: 0.5;
    } 
    i {
      margin-right: 5px;
    }
  }
  .post-tags {
    margin-left: 35px; 
  }
}

.format-chat {
  .entry-content {
    p {
      margin-bottom: 0.5em;
    }
    p:nth-child(2n) {
      padding: 0.4em 0;
    b {
      color: $colorMain7; 
    }
    }
  }
  b {
    color: #4c4c4c;
    font-weight: 600;
  }
}

.format-quote {
 &.cover-image {
    padding: 80px 30px 77px;
    @media(max-width: 767px) {
      padding: 40px 30px;
    }
  }
  .avatar {
    max-width: 120px;
    width: 120px;
    border-width: 6px;
  }
  border-radius: 5px;
  text-align: center;
  &.cover-image {
    .entry-header > i {
      display: inline-block;
      font-size: 2em;
      margin-bottom: 0.7em;
    }
  }
  blockquote {
    border: none;
    font-style: italic;
    margin-bottom: 26px;
    padding-bottom: 0;
    padding-left: 0;
    cite {
      display: block;
      font-style: normal;
      margin: 24px 0;
      font-size: 16px;
      line-height: 1.5;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.4em;
    }
  }
  &.s-overlay.ds:before {
    border-radius: 5px;
  }
 .entry-header {
    display: block;
  }
  .entry-meta { 
    a {
      letter-spacing: 0;
    }
    i {
      margin-right: 5px;
    }
  }
  .post-tags {
    margin-left: 25px;
  }
}

.share_buttons {
  display: flex;
  a {
    display: block;
    width: 100%;
    &:before {
      line-height: 60px;  
      height: 60px;
    }
  &.fa {
      margin: 0 1px 0 0;
      &:last-child {
        margin: 0;
        border-radius: 0 0 5px 0; 
      }
      &:first-child {
        border-radius: 0 0 0 5px; 
      }
    }
  }
}

.related-posts {
  margin-top: 44px;
  @media (max-width: 767px) {
    margin-top: 10px;
  }
  .entry-meta a {
    font-size: 16px;
    text-transform: inherit;
    color: $colorMain2;
    letter-spacing: 0;
    &:hover {
      color: $colorMain;
    }
  }
  .vertical-item .item-media + .item-content {
    padding: 0;
  }
  .entry-header {
    display: block;
    margin-bottom: 0;
  }
  h3.entry-title {
    margin-bottom: 35px;
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
}

.entry-header p {
  font-weight: 500;
  margin-top: 9px;
  line-height: 1.5;
  color: #4c4c4c;
  margin-bottom: 12px;
}

.byline {
  span {
    margin: 0 15px; 
    @media (max-width: 767px) {
      margin: 0 5px;
      line-height: 20px;  
    }
    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}

.post-gallery img {
  width: 210px;
  height: 190px;
  border-radius: 5px;
  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
  }
}

.single-post {
  .excerpt {
    margin-bottom: 20px;
  }
  ul.list1 {
    margin-top: 17px;
    margin-bottom: 0;
    li {
      padding: 3px 0;
      &:before {
        padding-right: 13px;
      }
    }
  }
}

.post-gallery {
  margin: 43px 0 29px; 
  @media (max-width: 767px) {
    margin: 27px 0 10px;
  }
}

.tags-links {
  span {
    margin-right: 17px; 
  }
  a {
    color: $colorMain2;
    letter-spacing: 0;
    &:hover {
      color: #4c4c4c;
    }
  }
}

.comment-reply-title {
  margin-top: 48px;
  @media (max-width: 767px) {
    margin-top: 31px;
  }
}

.entry-meta.post-meta {
  a {
    letter-spacing: 0;
  }
  i {
    margin-right: 7px;
  }
  span:first-child {
    margin-right: 30px;
  }
}

.author-avatar {

  img {
    max-width: 100px;
    border: 5px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 50% !important;
    position: absolute;
    right: 60px;
    top: -50px;
    bottom: auto;
    z-index: 3;
  }
  @media(max-width: 767px) {
    display: none;
  }
}


.post .item-media .owl-theme .owl-dots {
  right: 20px !important;
  left: auto !important;
  bottom: auto !important;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
 .owl-dot:after {
    color: #fff ;
  }
}

.format-link,
.format-aside {
  .author-avatar {
    margin-left: auto;
     img {
      position: static;
    }
  }
  .entry-header {
    margin-bottom: 10px;
  }
}

.avatar {
  display: block;
  max-width: 100px;
  border-radius: 50%;
  margin: 0 auto 25px;
  border: 5px solid rgba(255, 255, 255, 0.1);
}

.widget-title.categories-tilte {
  margin-bottom: 15px;
}

.widget.widget_text {
  .widget-title {
    margin-bottom: 26px;
  }
  a.btn {
    margin-top: 20px;
  }
}

.post-like {
  @media (max-width: 767px) {
    display: block;
    margin-top: 5px;
    float: left;
  }
}

.post .item-media {
  overflow: visible;
}

.blog-grid {
  .format-quote.cover-image {
    padding: 30px;
  }
  .post .item-media .owl-theme .owl-dots {
    top: 25%;
  }
  .post .entry-header {
    margin-bottom: 10px;
  }
  .navigation.pagination {
    margin-top: 30px;
  }
}


.gallery.post .item-media {
  overflow: hidden;
}


@media (max-width: 767px) {
  .post {
    .entry-meta {
      margin-top: 25px;
    }
  }
}


@media (max-width: 767px) {
  .post.single-post {
    .entry-meta {
      margin-top: 0;
    }
  }
}


.post .item-content {
  position: relative;
}

.reply {
  @media (min-width: 1400px) {
    margin-right: 3px;
  }
}

 @media (max-width: 1200px) {
  .format-aside {
    .post-data {
      width: 120px;
    }
  }
}