ul, ol {
  padding-left: 1.15rem;
}

dt {
  font-weight: 700;
}

dd {
  margin: 0 1.5em 1.5em;
}

.list-bordered {
  padding: 0;
  list-style: none;
  overflow: hidden;
  li {
    padding-top: 14px;
    padding-bottom: 15px;
    border-top: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
  }
  li + li {
    border-top: none;
  }
  &.no-top-border > li:first-child {
    border-top: none;
  }
  &.no-bottom-border > li:last-child {
    border-bottom: none;
  }
}

.list-styled ul,
ul.list-styled {
  list-style: none;
  padding: 0;
  color: $darkgreyColor;
  li {
    position: relative;
    padding-left: 20px;
    &:before {
      font-family: 'FontAwesome';
      content: "\f054";
      font-size: 10px;
      color: $colorMain;
      position: relative;
      padding-right: 15px;
      top: -2px;
    }
  }
}

.list1 ul,
ul.list1 {
  list-style: none;
  padding: 0;
  color: $darkgreyColor;
  li {
    padding: 2px 0;
    position: relative;
    &:before {
      font-family: 'FontAwesome';
      content: "\f111";
      font-size: 5px;
      color: $colorMain2;
      position: relative;
      padding-right: 8px;
      top: -3px;
    }
  }
}