.cs {

  .btn-action {
    i {
      color: $darkgreyColor;
    }
    background-color: $lightColor;
    &:hover i {
      opacity: 0.7;
    }
  }

}