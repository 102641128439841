/*
widget_archive
widget_categories
widget_nav_menu
widget_meta
widget_pages
widget_recent_comments
widget_recent_entries
*/
.widget_archive,
.widget_categories,
  //widget pages has following div inside:
.widget_nav_menu,
  //.menu-all-pages-container,
.widget_meta,
.widget_pages,
.widget_recent_comments,
.widget_recent_entries {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
    ul {
      li {
        padding-left: 20px;
        &:last-child {
          padding-bottom: 0;
          border-bottom: none;
        }
      }
    }
  }
  li {
    border-top: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
    &:first-child {
      border-top: 0;
    }
  }
  li + li {
    border-top: none;
  }
  //fix for unit test
  //empty menu item
  a:empty,
  li > a:not([href]) {
    display: none !important;
  }
}

.widget_archive,
.widget_categories,
  //widget pages has following div inside:
.widget_nav_menu,
  //.menu-all-pages-container,
.widget_meta,
.widget_pages {
  li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    a {
      color: #808080;
      flex-grow: 10;
      display: inline-block;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.widget_recent_comments,
.widget_recent_entries {
  li {
    padding-top: 12px;
    padding-bottom: 12px;
    
    &:before {
      @include widget-a-before();
    }
  }
}


//nav menu in topline and copyright
.page_copyright, .page_topline {
  .widget_nav_menu li {
    display: inline-block;
    position: relative;
    border: none;
    padding-right: 5px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .widget_nav_menu li:before {
    content: '';
    position: absolute;
    padding: 0;
    left: -5px;
    top: 5px;
    bottom: 5px;
    width: 1px;
    background-color: $borderColorRGBA;
    opacity: 0.3;
    display: block;
  }
  .widget_nav_menu li:first-child:before {
    display: none;
  }
  .widget_nav_menu a {
    padding: 0 10px;
  }
  .widget_nav_menu a:before {
    display: none;
  }
}

//dropdown lists
.widget_categories,
.widget_archive {
  select {
    width: 100%;
    max-width: 370px;
  }
}

